import React from "react"
import styled from "@emotion/styled"
import Newsletter from "./Footer/Newsletter"
import { colors } from "../styles/settings"
import Toggle from "./Nav/Toggle"
import { AnimatePresence, motion } from "framer-motion"
import { StaticQuery, graphql } from "gatsby"

const ENews = ({ show, setshowENews, eNewsSettings }) => {
  const { eNewsHeading, eNewsText, eNewsMailchimp } = eNewsSettings || {}

  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulGlobal(
            limit: 1
            filter: { title: { eq: "Global Settings" } }
          ) {
            edges {
              node {
                eNewsHeading
                eNewsText
                eNewsMailchimp
              }
            }
          }
        }
      `}
      render={data => {
        if (!data.allContentfulGlobal || !data.allContentfulGlobal.edges[0])
          return null
        const { node } = data.allContentfulGlobal.edges[0]
        const {
          eNewsHeading: eNewsHeadingDefault,
          eNewsText: eNewsTextDefault,
          eNewsMailchimp: eNewsMailchimpDefault,
        } = node || {}
        return (
          <ZIndexWrapper>
            <AnimatePresence>
              {show && (
                <motion.div
                  // initial={{ opacity: 0, y: 10 }}
                  // animate={{ opacity: 1, y: 0 }}
                  // exit={{ opacity: 0, y: 10 }}

                  // initial={{ y: 10 }}
                  // animate={{ y: 50 }}
                  // exit={{ y: 10 }}

                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.25 }}
                >
                  <Wrapper>
                    <Header>
                      <ToggleWrapper
                        onClick={() => {
                          setshowENews(false)
                        }}
                      >
                        <svg
                          width="17"
                          height="18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#ffffff"
                            d="M1.781.868l14.85 14.85-1.415 1.413L.367 2.282z"
                          />
                          <path
                            fill="#ffffff"
                            d="M.367 15.718L15.217.868l1.414 1.415-14.85 14.85z"
                          />
                        </svg>
                      </ToggleWrapper>
                    </Header>
                    <ContentWrapper>
                      <Newsletter
                        heading={eNewsHeading || eNewsHeadingDefault}
                        mailchimp={eNewsMailchimp || eNewsMailchimpDefault}
                        text={eNewsText || eNewsTextDefault}
                      />
                    </ContentWrapper>
                  </Wrapper>
                </motion.div>
              )}
            </AnimatePresence>
          </ZIndexWrapper>
        )
      }}
    />
  )
}
export default ENews

const Wrapper = styled.div`
  background-color: ${colors.peacock};
  position: fixed;
  bottom: 32px;
  right: 32px;
  max-width: 350px;
`

const ContentWrapper = styled.div`
  padding: 3rem 2rem;
  input {
    border-color: white !important;
  }
  .newsletter-description {
    color: white !important;
  }
  span {
    color: white !important;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
`

const ToggleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  svg {
    cursor: pointer;
  }
`

const ZIndexWrapper = styled.div`
  z-index: 99999;
  position: relative;
`
