import React from "react"

const Instagram = ({ color }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM18.7233 11.2773C20.0886 11.2152 20.5249 11.2 24.0012 11.2H23.9972C27.4746 11.2 27.9092 11.2152 29.2746 11.2773C30.6373 11.3397 31.5679 11.5555 32.384 11.872C33.2266 12.1987 33.9386 12.636 34.6506 13.348C35.3627 14.0595 35.8 14.7736 36.128 15.6155C36.4427 16.4294 36.6587 17.3595 36.7227 18.7222C36.784 20.0876 36.8 20.5238 36.8 24.0001C36.8 27.4764 36.784 27.9116 36.7227 29.277C36.6587 30.6391 36.4427 31.5695 36.128 32.3837C35.8 33.2253 35.3627 33.9394 34.6506 34.6509C33.9394 35.3629 33.2264 35.8013 32.3848 36.1283C31.5703 36.4448 30.6391 36.6605 29.2765 36.7229C27.9111 36.7851 27.4762 36.8003 23.9996 36.8003C20.5236 36.8003 20.0876 36.7851 18.7222 36.7229C17.3598 36.6605 16.4294 36.4448 15.615 36.1283C14.7736 35.8013 14.0595 35.3629 13.3483 34.6509C12.6365 33.9394 12.1992 33.2253 11.872 32.3834C11.5557 31.5695 11.34 30.6394 11.2773 29.2767C11.2155 27.9114 11.2 27.4764 11.2 24.0001C11.2 20.5238 11.216 20.0873 11.2771 18.7219C11.3384 17.3598 11.5544 16.4294 11.8717 15.6152C12.1997 14.7736 12.6371 14.0595 13.3491 13.348C14.0606 12.6363 14.7747 12.1989 15.6166 11.872C16.4305 11.5555 17.3606 11.3397 18.7233 11.2773Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.853 13.5066C23.0759 13.5063 23.3158 13.5064 23.5746 13.5065L24.0013 13.5066C27.4189 13.5066 27.824 13.5189 29.1736 13.5802C30.4216 13.6373 31.0989 13.8458 31.5501 14.021C32.1475 14.253 32.5733 14.5304 33.0211 14.9784C33.4691 15.4264 33.7464 15.853 33.979 16.4504C34.1542 16.9011 34.363 17.5784 34.4198 18.8264C34.4811 20.1758 34.4944 20.5811 34.4944 23.9971C34.4944 27.4132 34.4811 27.8185 34.4198 29.1678C34.3627 30.4159 34.1542 31.0932 33.979 31.5439C33.747 32.1412 33.4691 32.5665 33.0211 33.0143C32.5731 33.4623 32.1477 33.7396 31.5501 33.9716C31.0995 34.1476 30.4216 34.3556 29.1736 34.4127C27.8242 34.474 27.4189 34.4874 24.0013 34.4874C20.5834 34.4874 20.1783 34.474 18.8289 34.4127C17.5809 34.3551 16.9036 34.1466 16.4521 33.9714C15.8548 33.7394 15.4281 33.462 14.9801 33.014C14.5321 32.566 14.2548 32.1404 14.0222 31.5428C13.847 31.0921 13.6382 30.4148 13.5814 29.1668C13.5201 27.8174 13.5078 27.4121 13.5078 23.9939C13.5078 20.5758 13.5201 20.1726 13.5814 18.8232C13.6385 17.5752 13.847 16.8979 14.0222 16.4466C14.2542 15.8493 14.5321 15.4226 14.9801 14.9746C15.4281 14.5266 15.8548 14.2493 16.4521 14.0168C16.9033 13.8408 17.5809 13.6328 18.8289 13.5754C20.0097 13.5221 20.4674 13.5061 22.853 13.5034V13.5066ZM30.8339 15.632C29.9859 15.632 29.2978 16.3192 29.2978 17.1675C29.2978 18.0155 29.9859 18.7035 30.8339 18.7035C31.6819 18.7035 32.3699 18.0155 32.3699 17.1675C32.3699 16.3194 31.6819 15.6314 30.8339 15.6314V15.632ZM17.4279 24.0001C17.4279 20.37 20.3709 17.4268 24.001 17.4267C27.6312 17.4267 30.5736 20.3699 30.5736 24.0001C30.5736 27.6302 27.6314 30.5721 24.0013 30.5721C20.3711 30.5721 17.4279 27.6302 17.4279 24.0001Z"
        fill={color}
      />
      <path
        d="M24.0011 19.7334C26.3574 19.7334 28.2678 21.6436 28.2678 24.0001C28.2678 26.3564 26.3574 28.2668 24.0011 28.2668C21.6445 28.2668 19.7344 26.3564 19.7344 24.0001C19.7344 21.6436 21.6445 19.7334 24.0011 19.7334Z"
        fill={color}
      />
    </svg>
  )
}

export default Instagram
