import _ from "lodash"

export const getCurrSlugSection = (location, sections) => {
  if (!sections || (sections && sections.length === -1)) return

  const subSlugs = sections.map(s => s.slug)

  // remove leading slash, trailing slash, convert to array
  const pathnameParts = location.pathname
    .replace(/\/$/, "")
    .replace(/^\/+/, "")
    .split("/")

  const currSlug =
    pathnameParts.length === 1
      ? subSlugs[0]
      : subSlugs[subSlugs.indexOf(pathnameParts[1])]
  const currSection = _.find(sections, { slug: currSlug })
  return {
    currSlug,
    currSection,
  }
}

export const getValidSection = (sections, section) => {
  let valid = _.find(sections, { title: section })

  if (!valid) {
    valid = null
  }

  return valid
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}
