import "../styles/index.css"
import "../styles/lightbox-overrides.css"
import React, { useState, useEffect } from "react"
import { ThemeProvider, useThemeUI } from "theme-ui"
import styled from "@emotion/styled"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import FeatureVideo from "../components/FeatureVideo"
import { VideoProvider } from "../hooks/useFeatureVideo"
import ENews from "../components/ENews"
import { getWithExpiry, setWithExpiry } from "../util/helpers"

const Layout = ({ children, hasHero, eNewsSettings }) => {
  const context = useThemeUI()
  const { theme } = context
  const [showEnews, setshowENews] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const doesExist = getWithExpiry("eNews")
      if (doesExist !== "true") {
        setshowENews(true)
        setWithExpiry("eNews", "true", 1000 * 60 * 10)
      }
    }, 2000)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <VideoProvider>
        <>
          <ENews
            eNewsSettings={eNewsSettings}
            show={showEnews}
            setshowENews={setshowENews}
          />
          <Page>
            <Nav hasHero={hasHero} />
            <Main>{children}</Main>
            <Footer />
          </Page>
          <FeatureVideo />
        </>
      </VideoProvider>
    </ThemeProvider>
  )
}

const Page = styled.div``
const Main = styled.main``

export default Layout
