import React, { useContext, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import ReactPlayer from "react-player"
import { VideoContext } from "../hooks/useFeatureVideo"
import Hls from "hls.js"

const FeatureVideo = () => {
  const [videoUrl, setVideoUrl] = useContext(VideoContext)

  const videoRef = useRef()
  const streamUrl = videoUrl
  useEffect(() => {
    let hls
    const src = streamUrl
    if (videoRef.current && streamUrl !== "") {
      const video = videoRef.current
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src
      } else if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
        setTimeout(() => {
          video.play()
        }, 1000)
      } else {
        console.error("This is a legacy browser that doesn't support MSE")
      }
    }
    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef, videoUrl])
  if (!videoUrl || videoUrl === "") return null
  return (
    <Wrap>
      <Bg onClick={() => setVideoUrl(null)} />
      <VideoBoxOuter>
        <VideoBoxAspect>
          <VideoBox>
            <video
              ref={videoRef}
              className="video-player"
              loop={false}
              controls={true}
              autoPlay={true}
              width="100%"
              height="100%"
              playsinline={true}
              url={videoUrl}
            />
          </VideoBox>
        </VideoBoxAspect>
      </VideoBoxOuter>
    </Wrap>
  )
}

export default FeatureVideo

const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Bg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 250ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.37);
  }
`

const VideoBoxOuter = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1300px;
  padding: 20px;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 40px;
  }
`

const VideoBoxAspect = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: ${(9 / 16) * 100}%;
  background: transparent;
  position: relative;
`

const VideoBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
